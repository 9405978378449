import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from  '@material-ui/core/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Avatar,
 Menu, MenuItem, Button, Typography, Tooltip, Link, Chip} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import ContactSupport from '@material-ui/icons/ContactSupport';
import {Search, ExpandMore, PowerSettingsNew} from '@material-ui/icons';
import Resources from '../../../../service/resources';
import Configuration from '../../../../service/configuration';
import { red, orange } from '@material-ui/core/colors';
import SimpleSearchToolbar from './SimpleSearchToolbar';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import DataServices from '../../../../service/data-services';
import { useTranslation, withTranslation } from 'react-i18next'


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0px 1px 0px #e0e0e0',
    background: theme.palette.white
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
    const resources = new Resources( );
    const dataService = new DataServices( );
    const config = new Configuration();
    var userData = dataService.getCurrentUserData();

    const classes = useStyles();

    var t = props.lang;
    var i18n = props.i18n;

    const [notifications] = useState([]);
    const [lang, setLang] = useState(dataService.getLanguage());
    const [langName, setLangName] = useState( lang==='my'? "မြန်မာ" : (lang==='mm'? "ျမန္မာေဇာ္ဂ်ီ": "English"));
    const [langFlag, setLangFlag] = useState( (lang==='my' || lang==='mm')? "/myanmar.svg" : "/uk.svg");

    const onLanguageChange = lang=> {

        if( lang === 'my'){
            setLangName("မြန်မာ");
            setLangFlag("/myanmar.svg");
        }else if( lang === 'mm'){
            setLangName("ျမန္မာေဇာ္ဂ်ီ");
            setLangFlag("/myanmar.svg");
        }else{
            setLangName("English");
            setLangFlag("/uk.svg");
        }
        i18n.changeLanguage(lang);
        setLang(lang);
        dataService.setLanguage(lang);
    }

    const handleChipDelete = ()=>{
        dataService.setSwitchBranchGuid('');
        dataService.setSwitchBranchName('');
        window.location.href='/organization_admin_dashboard';
    };

  return (
    <AppBar
    position="fixed"
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <IconButton
          color="primary"
          edge="start"
          onClick={onSidebarOpen} >
          <MenuIcon />
        </IconButton>
        {/*<Hidden only={['md', 'lg', 'xl']}>
          <IconButton
            color="primary"
            edge="start"
            onClick={onSidebarOpen} >
            <MenuIcon />
          </IconButton>
        </Hidden>*/}
        <RouterLink to="/">
        {/*<Avatar
             alt={t('site.name')}
             src={"/qq-web-logo.png"}
             style={{width: 50,height: 50, marginRight:40}} />*/}
             <img alt={t('site.name')} src={"/logo-2.png"} style= { {width: '150px' }}  />
          {/*<img
            alt="Myanmar Beer Premium"
            src={"/mbl-logo.jpeg"} style={{width:50, marginRight:40}} />*/}
        </RouterLink>
        {/*<Typography variant="h4" color="primary">{t('site.name')}</Typography>*/}
        {/*<SimpleSearchToolbar />*/}
        {dataService.getSwitchBranchGuid( ) &&
            <Chip className={classes.signOutButton}
              label={'Working As: '+dataService.getSwitchBranchName( )}
              onDelete={handleChipDelete}
              variant='outlined'
              size="medium"
              color="primary"
            />
        }
        <div className={classes.flexGrow} />

        <Hidden xsDown>
            {/*<Tooltip title="Search" arrow>
              <IconButton
                color="icon" >
                <Search />
              </IconButton>
            </Tooltip>*/}

            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button style={{textTransform: 'none'}} {...bindTrigger(popupState)}
                  startIcon={<img alt="Language" src={langFlag} style={{width:24, marginRight:4}} />}
                  endIcon={<ExpandMore />} >
                    {langName}
                  </Button>

                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={()=>{onLanguageChange('my');popupState.close();}}>
                    <img alt="My" src={"/myanmar.svg"} style={{width:24, marginRight:8}} />
                     <Typography variant="button"
                         style={{textTransform: 'none'}} >
                         မြန်မာ
                    </Typography>
                    </MenuItem>

                    <MenuItem onClick={()=>{onLanguageChange('mm');popupState.close();}}>
                    <img alt="My" src={"/myanmar.svg"} style={{width:24, marginRight:8}} />
                     <Typography variant="button"
                         style={{textTransform: 'none'}} >
                         ျမန္မာေဇာ္ဂ်ီ
                    </Typography>
                    </MenuItem>

                    <MenuItem onClick={()=>{onLanguageChange('en');popupState.close();}}>
                        <img alt="Eng" src={"/uk.svg"} style={{width:24, marginRight:8}} />
                         <Typography variant="button"
                             style={{textTransform: 'none'}} >
                             English
                        </Typography>
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>

            {/*<Tooltip title="Notifications" arrow>
              <IconButton color="icon">
                <Badge
                  badgeContent={3}
                  color="error"
                  variant="dot"
                  max={999} >
                 <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>*/}

            {/*
            <Link
                component="a"
                href="https://www.facebook.com/quickdoortodoor"
                target="_blank">
                <Tooltip title="Help" arrow>
                  <IconButton
                    color="icon" >
                    <ContactSupport />
                  </IconButton>
                </Tooltip>
            </Link>
            */}
            {/*<Tooltip title="Logout" arrow>
              <IconButton
                className={classes.signOutButton}
                color="icon" onClick={event =>{dataService.removeTokenCookie();}} >
                <PowerSettingsNew />
              </IconButton>
            </Tooltip>*/}

            {(userData && userData['avatar']) &&
                <Tooltip enterDelay={200} leaveDelay={500}
                title={userData?(userData['name'] +' @ ' + (userData['roleName']==='Employee'? 'Deliveryman': (userData['roleName']==='OS'?'Merchant':userData['roleName']) ) ):''}
                 arrow style={{cursor:'pointer'}}>
                    <Avatar src={resources.BACKEND_SIDE_BASE_URL + config.SERVICE_NAME_IMAGE + config.SERVICE_ACTION_IMAGE_DOWNLOAD + userData['avatar']}
                     onClick={()=>{ window.location.href='/user_profile'; } }
                     />
                </Tooltip>
            }

            {(userData && !userData['avatar']) &&
                <Tooltip enterDelay={200} leaveDelay={500} title={
                    userData?(userData['name'] +' @ ' + (userData['roleName']==='Employee'? 'Deliveryman': (userData['roleName']==='OS'?'Merchant':userData['roleName']) ) ):''
                } arrow style={{cursor:'pointer'}}>
                    <Avatar style={{backgroundColor: 'primary'}}
                        onClick={()=>{ window.location.href='/user_profile'; } }>
                        {userData?userData['name'][0].toUpperCase() :'U'}
                    </Avatar>
                </Tooltip>
            }

        </Hidden>

      </Toolbar>
     
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

//export default Topbar;
export default (props) => {
    const { t, i18n } = useTranslation();
    return (<Topbar lang={t} i18n={i18n} props{... props} />)
}
